import React from 'react';
import Box from '@webAtoms/Box/Box';
import PageHeading from '@webMolecules/PageHeading/PageHeading';
import UpNext from '@webMolecules/UpNext/UpNext';

import * as Route from '@routes';
import * as styles from './responsibility.module.scss';
import Intro, { IntroProps } from './Sections/Intro';
import Section, { SectionProps } from './Sections/Section';

export interface ResponsibilityProps {
  title: string;
  intro: IntroProps;
  sections: Omit<SectionProps, 'index'>[];
}

const Responsibility: React.FC<ResponsibilityProps> = ({
  title,
  intro,
  sections,
}) => {
  return (
    <Box className={styles.responsibility}>
      <PageHeading title={title} color="var(--color--white)" />

      <Intro {...intro} />

      {sections.map((section, index) => (
        <Section key={index} index={index} {...section} />
      ))}

      <UpNext
        links={[
          {
            label: 'Sustainability report',
            href: Route.sustainabilityReport(),
          },
          {
            label: 'Modular homes',
            href: Route.designs(),
          },
        ]}
      />
    </Box>
  );
};

export default Responsibility;
