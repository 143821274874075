import React from 'react';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import * as styles from './section.module.scss';
import Column, { ColumnProps } from './Column';

export interface SectionProps {
  index: number;
  title?: string;
  body?: string;
  columns?: ColumnProps[];
  file?: string;
}

const Section: React.FC<SectionProps> = ({ index, title, body, columns }) => {
  return (
    <Box className={styles.section}>
      <Container>
        <Box
          className={[
            styles.sectionColumns,
            `index-${index}`,
            `columns-${columns?.length}`,
          ].join(' ')}
        >
          {columns?.map((column, index) => (
            <Column key={index} {...column} totalColumns={columns.length} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Section;
