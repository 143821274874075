import React from 'react';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import * as styles from './intro.module.scss';

export interface IntroProps {
  title: string;
  body: string;
  image?: IGatsbyImageData;
}

const Intro: React.FC<IntroProps> = ({ title, body, image }) => {
  return (
    <Box className={styles.intro}>
      <Container>
        <Box className={styles.introSection}>
          <Box className={styles.introSectionImageWrapper}>
            {image && <GatsbyImage loading="lazy" image={image} alt={title} />}
          </Box>
          <Box className={styles.introSectionContent} htmlContent={body} />
        </Box>
      </Container>
    </Box>
  );
};

export default Intro;
