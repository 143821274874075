import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';
import Base from '@webLayouts/Base/Base';
import Responsibility from '@webPages/About/Responsibility/Responsibility';
import { PageResponsibilityQuery } from '@generated/GraphqlTypes';
import { SectionProps } from '@webPages/About/Responsibility/Sections/Section';

const ResponsibilityReportPage: React.FC<
  PageProps<PageResponsibilityQuery>
> = ({ data }) => {
  const title = data?.responsibility?.frontmatter?.title || '';

  const seoTitle = data?.responsibility?.frontmatter?.seoTitle || undefined;
  const seoDescription =
    data?.responsibility?.frontmatter?.seoDescription ||
    data?.responsibility?.excerpt ||
    undefined;

  const image =
    data?.responsibility?.frontmatter?.heroImage?.publicURL || undefined;
  const body = data?.responsibility?.html || '';

  const intro = {
    title,
    body,
    image: getImage(
      data?.responsibility?.frontmatter?.heroImage as ImageDataLike
    ),
  };

  const file = data?.responsibility?.frontmatter?.file?.publicURL;

  const sections: Omit<SectionProps, 'index'>[] =
    data?.responsibility?.frontmatter?.sections?.map(section => ({
      // title: section?.title ?? undefined,
      // body: section?.body ?? undefined,
      columns: section?.columns?.map(column => ({
        title: column?.title ?? undefined,
        body: column?.body ?? undefined,
        image: column?.image?.publicURL ?? undefined,
        file,
      })),
    })) ?? [];

  return (
    <Base
      theme="primary-all"
      title={seoTitle}
      description={seoDescription}
      image={image}
    >
      <Responsibility title={title} intro={intro} sections={sections} />
    </Base>
  );
};

export default ResponsibilityReportPage;

export const query = graphql`
  query PageResponsibility {
    responsibility: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "responsibility" } }
    ) {
      html
      excerpt
      frontmatter {
        title
        seoTitle
        seoDescription

        #file {
        #  publicURL
        #}

        sections {
          columns {
            title
            image {
              publicURL
            }
            body
          }
        }

        heroImage {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 2000
              # placeholder: BLURRED
              # quality: 70
              # formats: [AUTO, WEBP, AVIF, JPG]
            )
          }
        }
      }
    }
  }
`;
